import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sidebarShow: 'responsive',
    sidebarMinimize: false,

    userData: {
      authenticated: false,
      token: '',
      name: '',
      email: '',
      avatar: '',
    },

  },
  mutations: {
    toggleSidebarDesktop(state) {
      const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
      state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile(state) {
      const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
      state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    set(state, [variable, value]) {
      state[variable] = value
    },

    setUserDataMutation(state, datos) {
      state.userData = datos
    },
  },
  actions: {
    addUserDataAction(context, datos) {
      context.commit('setUserDataMutation', datos)
    },
  },
  getters: {
    getUserData(state) {
      return state.userData
    },
  }
})