import Vue from 'vue'
import Router from 'vue-router'
import Store from '/src/store'
// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

// const Colors = () => import('@/views/theme/Colors')
// const Typography = () => import('@/views/theme/Typography')

const Charts = () => import('@/views/charts/Charts')

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

// ConfiguracionApp - Pages
const ConfiguracionApp = () => import('@/views/configuracionApp/List')
const ConfiguracionAppCreate = () => import('@/views/configuracionApp/Create')
const ConfiguracionAppUpdate = () => import('@/views/configuracionApp/Update')
const ConfiguracionAppDelete = () => import('@/views/configuracionApp/Delete')
const ConfiguracionAppView = () => import('@/views/configuracionApp/View')

// EcoAtributosGrupos - Pages
const EcoAtributosGrupos = () => import('@/views/ecoAtributosGrupos/List')
const EcoAtributosGruposCreate = () => import('@/views/ecoAtributosGrupos/Create')
const EcoAtributosGruposUpdate = () => import('@/views/ecoAtributosGrupos/Update')
const EcoAtributosGruposDelete = () => import('@/views/ecoAtributosGrupos/Delete')
const EcoAtributosGruposView = () => import('@/views/ecoAtributosGrupos/View')

// EcoAtributos - Pages
const EcoAtributos = () => import('@/views/ecoAtributos/List')
const EcoAtributosCreate = () => import('@/views/ecoAtributos/Create')
const EcoAtributosUpdate = () => import('@/views/ecoAtributos/Update')
const EcoAtributosDelete = () => import('@/views/ecoAtributos/Delete')
const EcoAtributosView = () => import('@/views/ecoAtributos/View')

// EcoCategorias - Pages
const EcoCategorias = () => import('@/views/ecoCategorias/List')
const EcoCategoriasCreate = () => import('@/views/ecoCategorias/Create')
const EcoCategoriasUpdate = () => import('@/views/ecoCategorias/Update')
const EcoCategoriasDelete = () => import('@/views/ecoCategorias/Delete')
const EcoCategoriasView = () => import('@/views/ecoCategorias/View')

// EcoMarcas - Pages
const EcoMarcas = () => import('@/views/ecoMarcas/List')
const EcoMarcasCreate = () => import('@/views/ecoMarcas/Create')
const EcoMarcasUpdate = () => import('@/views/ecoMarcas/Update')
const EcoMarcasDelete = () => import('@/views/ecoMarcas/Delete')
const EcoMarcasView = () => import('@/views/ecoMarcas/View')

// EcoEtiquetas - Pages
const EcoEtiquetas = () => import('@/views/ecoEtiquetas/List')
const EcoEtiquetasCreate = () => import('@/views/ecoEtiquetas/Create')
const EcoEtiquetasUpdate = () => import('@/views/ecoEtiquetas/Update')
const EcoEtiquetasDelete = () => import('@/views/ecoEtiquetas/Delete')
const EcoEtiquetasView = () => import('@/views/ecoEtiquetas/View')

// EcoProductos - Pages
const EcoProductos = () => import('@/views/ecoProductos/List')
const EcoProductosCreate = () => import('@/views/ecoProductos/Create')
const EcoProductosUpdate = () => import('@/views/ecoProductos/Update')
const EcoProductosDelete = () => import('@/views/ecoProductos/Delete')
const EcoProductosView = () => import('@/views/ecoProductos/View')

// EcoBanners - Pages
const EcoBanners = () => import('@/views/ecoBanners/List')
const EcoBannersCreate = () => import('@/views/ecoBanners/Create')
const EcoBannersDelete = () => import('@/views/ecoBanners/Delete')
const EcoBannersView = () => import('@/views/ecoBanners/View')

Vue.use(Router)

const router = new Router({
    mode: 'hash',
    linkActiveClass: 'active',
    scrollBehavior: () => ({ y: 0 }),
    routes: configRoutes()
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(route => route.meta.requiresAuth)) {
        const userData = Store.getters.getUserData
        if (!userData.authenticated) {
            next('/pages/login')
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router
function configRoutes() {
    return [
        {
            path: '/',
            redirect: '/dashboard',
            name: 'Home',
            component: TheContainer,
            children: [
                {
                    path: 'dashboard',
                    name: 'Dashboard',
                    component: Dashboard,
                    meta: { requiresAuth: true }
                },
                // configuracionApp
                {
                    path: '/configuracionApp/list',
                    redirect: '/configuracionApp/list',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/configuracionApp/list',
                            name: 'Listar configuraciones de aplicacion',
                            component: ConfiguracionApp,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/configuracionApp/create',
                            name: 'Registrar configuracion de aplicacion',
                            component: ConfiguracionAppCreate,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/configuracionApp/update/:id',
                            name: 'Actualizar configuracion de aplicacion',
                            component: ConfiguracionAppUpdate,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/configuracionApp/delete/:id',
                            name: 'Borrar configuracion de aplicacion',
                            component: ConfiguracionAppDelete,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/configuracionApp/view/:id',
                            name: 'Ver configuracion de aplicacion',
                            component: ConfiguracionAppView,
                            meta: { requiresAuth: true }
                        }
                    ]
                },

                // Eco-atributos-grupos
                {
                    path: '/ecoAtributosGrupos/list',
                    redirect: '/ecoAtributosGrupos/list',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/ecoAtributosGrupos/list',
                            name: 'Listar eco atributos grupos',
                            component: EcoAtributosGrupos,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/ecoAtributosGrupos/create',
                            name: 'Registrar eco atributos grupos',
                            component: EcoAtributosGruposCreate,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/ecoAtributosGrupos/update/:id',
                            name: 'Actualizar eco atributos grupos',
                            component: EcoAtributosGruposUpdate,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/ecoAtributosGrupos/delete/:id',
                            name: 'Borrar eco atributos grupos',
                            component: EcoAtributosGruposDelete,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/ecoAtributosGrupos/view/:id',
                            name: 'Ver eco atributos grupos',
                            component: EcoAtributosGruposView,
                            meta: { requiresAuth: true }
                        }
                    ]
                },

                // Eco-atributos
                {
                    path: '/ecoAtributos/list',
                    redirect: '/ecoAtributos/list',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/ecoAtributos/list',
                            name: 'Listar eco atributos ',
                            component: EcoAtributos,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/ecoAtributos/create',
                            name: 'Registrar eco atributos ',
                            component: EcoAtributosCreate,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/ecoAtributos/update/:id',
                            name: 'Actualizar eco atributos',
                            component: EcoAtributosUpdate,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/ecoAtributos/delete/:id',
                            name: 'Borrar eco atributos',
                            component: EcoAtributosDelete,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/ecoAtributos/view/:id',
                            name: 'Ver eco atributos',
                            component: EcoAtributosView,
                            meta: { requiresAuth: true }
                        }
                    ]
                },

                // Eco-categorias
                {
                    path: '/ecoCategorias/list',
                    redirect: '/ecoCategorias/list',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/ecoCategorias/list',
                            name: 'Listar eco categorias ',
                            component: EcoCategorias,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/ecoCategorias/create',
                            name: 'Registrar eco categorias ',
                            component: EcoCategoriasCreate,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/ecoCategorias/update/:id',
                            name: 'Actualizar eco categorias',
                            component: EcoCategoriasUpdate,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/ecoCategorias/delete/:id',
                            name: 'Borrar eco categorias',
                            component: EcoCategoriasDelete,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/ecoCategorias/view/:id',
                            name: 'Ver eco categorias',
                            component: EcoCategoriasView,
                            meta: { requiresAuth: true }
                        }
                    ]
                },
                // Eco-marcas
                {
                    path: '/ecoMarcas/list',
                    redirect: '/ecoMarcas/list',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/ecoMarcas/list',
                            name: 'Listar eco marcas ',
                            component: EcoMarcas,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/ecoMarcas/create',
                            name: 'Registrar eco marcas ',
                            component: EcoMarcasCreate,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/ecoMarcas/update/:id',
                            name: 'Actualizar eco marcas',
                            component: EcoMarcasUpdate,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/ecoMarcas/delete/:id',
                            name: 'Borrar eco marcas',
                            component: EcoMarcasDelete,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/ecoMarcas/view/:id',
                            name: 'Ver eco marcas',
                            component: EcoMarcasView,
                            meta: { requiresAuth: true }
                        }
                    ]
                },

                // Eco-etiquetas
                {
                    path: '/ecoEtiquetas/list',
                    redirect: '/ecoEtiquetas/list',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/ecoEtiquetas/list',
                            name: 'Listar eco etiquetas ',
                            component: EcoEtiquetas,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/ecoEtiquetas/create',
                            name: 'Registrar eco etiquetas ',
                            component: EcoEtiquetasCreate,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/ecoEtiquetas/update/:id',
                            name: 'Actualizar eco etiquetas',
                            component: EcoEtiquetasUpdate,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/ecoEtiquetas/delete/:id',
                            name: 'Borrar eco etiquetas',
                            component: EcoEtiquetasDelete,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/ecoEtiquetas/view/:id',
                            name: 'Ver eco etiquetas',
                            component: EcoEtiquetasView,
                            meta: { requiresAuth: true }
                        }
                    ]
                },

                // Eco-productos
                {
                    path: '/ecoProductos/list',
                    redirect: '/ecoProductos/list',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/ecoProductos/list',
                            name: 'Listar eco productos ',
                            component: EcoProductos,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/ecoProductos/create',
                            name: 'Registrar eco productos ',
                            component: EcoProductosCreate,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/ecoProductos/update/:id',
                            name: 'Actualizar eco productos',
                            component: EcoProductosUpdate,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/ecoProductos/delete/:id',
                            name: 'Borrar eco productos',
                            component: EcoProductosDelete,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/ecoProductos/view/:id',
                            name: 'Ver eco productos',
                            component: EcoProductosView,
                            meta: { requiresAuth: true }
                        }
                    ]
                },

                // Eco-banners
                {
                    path: '/ecoBanners/list',
                    redirect: '/ecoBanners/list',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/ecoBanners/list',
                            name: 'Listar Banners ',
                            component: EcoBanners,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/ecoBanners/create',
                            name: 'Registrar Banner ',
                            component: EcoBannersCreate,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/ecoBanners/delete/:id',
                            name: 'Borrar Banner',
                            component: EcoBannersDelete,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: '/ecoBanners/view/:id',
                            name: 'Ver Banner',
                            component: EcoBannersView,
                            meta: { requiresAuth: true }
                        }
                    ]
                },


                {
                    path: 'charts',
                    name: 'Charts',
                    component: Charts
                },
                {
                    path: 'users',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Users',
                            component: Users,
                            meta: { requiresAuth: true }
                        },
                        {
                            path: ':id',
                            name: 'User',
                            component: User,
                            meta: { requiresAuth: true }
                        }
                    ]
                }
            ]
        },
        {
            path: '/pages',
            redirect: '/pages/404',
            name: 'Pages',
            component: {
                render(c) {
                    return c('router-view')
                }
            },
            children: [
                {
                    path: '404',
                    name: 'Page404',
                    component: Page404
                },
                {
                    path: '500',
                    name: 'Page500',
                    component: Page500
                },
                {
                    path: 'login',
                    name: 'Login',
                    component: Login
                },
                {
                    path: 'register',
                    name: 'Register',
                    component: Register
                }
            ]
        },
    ]
}