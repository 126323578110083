import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import 'regenerator-runtime/runtime'

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

import Multiselect from "vue-multiselect";
Vue.component("multiselect", Multiselect);

import Datepicker from "vuejs-datepicker";
Vue.use(Datepicker);

import Vue2Filters from "vue2-filters";
Vue.use(Vue2Filters);

import money from 'v-money'
Vue.use(money, {decimal: ',', thousands: '.', prefix: '$ ',precision: 2})

import VueToastify from 'vue-toastify'
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from 'vee-validate'
import es from 'vee-validate/dist/locale/es.json'
import * as rules from 'vee-validate/dist/rules'

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})
localize('es', es)
// Install VeeValidate components globally
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

Vue.use(require('vue-moment'))

Vue.use(VueToastify, {
  'position': 'top-right',
  'theme': 'dark'
})

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
